import { Link, graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import kebabCase from 'lodash/kebabCase'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import HeadSection from '~/components/blocks/HeadSection'
import * as containerStylesButtons from '~/components/buttons/Button/Button.module.scss'
import Fade from '~/components/shared/Fade'
import { AllStrapiVacancy } from '~/types/graphql'
import { cx } from '~/utils'

import * as containerStyles from './CareersVacancyListSection.module.scss'

const CareersVacancyListSection = () => {
  const query = useStaticQuery<AllStrapiVacancy>(graphql`
    query {
      allStrapiVacancy(filter: { active: { eq: true } }, sort: { hot: DESC }) {
        nodes {
          id
          hot
          title
        }
      }
    }
  `)

  const hasActiveVacancies = query.allStrapiVacancy.nodes.length > 0

  return (
    <>
      <HeadSection
        position="center"
        title="Open positions"
        description={
          hasActiveVacancies
            ? 'We want you in our team! Choose the position that applies best to your skills and expertise and apply.'
            : 'While there are no open positions at the moment, we’re always on the lookout for talented individuals. We welcome you to submit your resume for upcoming vacancies.'
        }
        descriptionLarge
      />
      {!hasActiveVacancies && (
        <div className={containerStyles.careersVacancyList__applyNowBtn}>
          <AnchorLink
            href="#sendCV"
            className={cx(
              containerStylesButtons.button,
              containerStylesButtons.button__red,
            )}
          >
            Apply now
          </AnchorLink>
        </div>
      )}

      {hasActiveVacancies && (
        <div className={containerStyles.careersVacancyList__wrapper}>
          <div className="container container-md">
            <div className="row">
              {query.allStrapiVacancy.nodes.map((vacancy) => (
                <div className="col-12 col-md-6" key={vacancy.title}>
                  <Fade
                    triggerOnce
                    direction="up"
                    className={containerStyles.careersVacancyList__reveal}
                  >
                    <Link
                      to={`/careers/vacancy/${kebabCase(
                        vacancy.title as string | undefined,
                      )}/`}
                      className={containerStyles.careersVacancyList__vacancy}
                    >
                      <div className="d-flex align-items-center">
                        {vacancy.hot && (
                          <StaticImage
                            src="../../../../assets/images/emoji/emoji-2.png"
                            alt="Hot Vacancy at Codica"
                            title="Hot Codica Vacancy"
                            width={22}
                            height={22}
                            className={containerStyles.careersVacancyList__hot}
                          />
                        )}
                        <span
                          className={containerStyles.careersVacancyList__title}
                        >
                          {vacancy.title}
                        </span>
                      </div>
                      <div
                        className={containerStyles.careersVacancyList__apply}
                      >
                        Apply <span />
                      </div>
                    </Link>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CareersVacancyListSection
