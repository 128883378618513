import { lazy } from 'react'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import PostsSliderMock from '~/mock/PostsSlider'

import * as containerStyles from './CompanyPostSliderSection.module.scss'

const PostsSlider = lazy(() => import('~/components/sliders/PostsSlider'))

const CompanyPostSliderSection = () => (
  <div className="container container-md">
    <div className="row">
      <RenderWhenVisible
        withSuspense
        fallback={<EmptyFallback height={452} />}
        className={containerStyles.companyPostsArtcilesSection__render}
      >
        <PostsSlider dataContent={PostsSliderMock} />
      </RenderWhenVisible>
    </div>
  </div>
)

export default CompanyPostSliderSection
