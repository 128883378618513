import { Link } from 'gatsby'

import LazyHydrate from '~/components/shared/LazyHydrate'
import SuccessStoriesSlider from '~/components/sliders/SuccessStoriesSlider'
import successStoriesSlider from '~/mock/SuccessStoriesSlider'
import { SuccessStoriesSliderMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './SuccessStoriesSliderSection.module.scss'

interface SuccessStoriesSliderSectionProps {
  btnName?: string
  dataContent?: SuccessStoriesSliderMock[]
  withOutButtons?: boolean
  btnClassName?: string
}

const SuccessStoriesSliderSection = ({
  btnName,
  dataContent,
  withOutButtons,
  btnClassName,
}: SuccessStoriesSliderSectionProps) => (
  <div className="container container-md">
    <div className="row">
      <div
        className={cx(
          containerStyles.successStoriesSliderContainer__wrapper,
          'col-12',
        )}
      >
        <LazyHydrate whenVisible>
          <SuccessStoriesSlider
            withOutButtons={withOutButtons}
            dataContent={dataContent || successStoriesSlider}
          />
        </LazyHydrate>
      </div>

      {btnName && (
        <div
          className={cx(
            containerStyles.successStoriesSliderContainer__btnWrapper,
            'col-12',
          )}
        >
          <Link
            to="/case-studies/"
            className={cx(
              btnClassName,
              containerStyles.successStoriesSliderContainer__button,
            )}
          >
            {btnName}
          </Link>
        </div>
      )}
    </div>
  </div>
)

export default SuccessStoriesSliderSection
