import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import Fade from '~/components/shared/Fade'
import { cx, fileToImageLikeData } from '~/utils'

import useCareersStaticQuery from '../../useCareersStaticQuery'

import * as containerStyles from './CareersPlaceWorkSection.module.scss'

const CareersPlaceWorkSection = () => {
  const query = useCareersStaticQuery()

  const photos = [
    {
      src: getImage(fileToImageLikeData(query.companyPhoto1)),
      key: 'companyPhoto-1',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto2)),
      key: 'companyPhoto-2',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto15)),
      key: 'companyPhoto-3',
      className: 'imageLarge',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto5)),
      key: 'companyPhoto-4',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto6)),
      key: 'companyPhoto-5',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto3)),
      key: 'companyPhoto-6',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto4)),
      key: 'companyPhoto-7',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto7)),
      key: 'companyPhoto-8',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto8)),
      key: 'companyPhoto-9',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto9)),
      key: 'companyPhoto-10',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto10)),
      key: 'companyPhoto-11',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto11)),
      key: 'companyPhoto-12',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto12)),
      key: 'companyPhoto-13',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto13)),
      key: 'companyPhoto-14',
    },
    {
      src: getImage(fileToImageLikeData(query.companyPhoto14)),
      key: 'companyPhoto-15',
    },
  ]

  const social = [
    {
      name: 'Instagram',
      link: 'https://www.instagram.com/codica_com/',
    },
    {
      name: 'Telegram',
      link: 'https://t.me/codica',
    },
    {
      name: 'TikTok',
      link: 'https://www.tiktok.com/@codica_com',
    },
  ]

  return (
    <>
      <div className={containerStyles.careersPlaceWork__socialButtonsWrapper}>
        {social.map(({ name, link }) => (
          <a
            key={name}
            href={link}
            target="_blank"
            aria-label={name}
            data-id={`careersSocialLink${name}`}
            className={cx(
              containerStyles.careersPlaceWork__socialButton,
              containerStyles[`careersPlaceWork__socialButton_${name}`],
            )}
          >
            {name}
          </a>
        ))}
      </div>

      <ul className={containerStyles.careersImageGrid}>
        {photos.map(({ key, src, className }) => (
          <li
            key={key}
            className={containerStyles[`careersImageGrid__${className}`]}
          >
            <div className={containerStyles.careersImageGrid__wrapper}>
              <Fade triggerOnce direction="up">
                <GatsbyImage
                  image={src as IGatsbyImageData}
                  alt="Codica - an exciting place to work | Codica"
                  title="Codica - an exciting place to work"
                />
              </Fade>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default CareersPlaceWorkSection
