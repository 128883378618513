import { lazy } from 'react'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'

import type { SuccessStoriesSliderProps } from './SuccessStoriesSlider.view'

const SuccessStoriesSlider = lazy(() => import('./SuccessStoriesSlider.view'))

const SuccessStoriesSliderWrapper = (props: SuccessStoriesSliderProps) => (
  <RenderWhenVisible withSuspense fallback={<EmptyFallback minHeight={380} />}>
    <SuccessStoriesSlider {...props} />
  </RenderWhenVisible>
)

export default SuccessStoriesSliderWrapper
