import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './CareersFluidImageSection.module.scss'

const CareerFluidImageSection = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      ourExpertTeam: file(relativePath: { eq: "careers/our-expert-team.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const ourExpertTeam = getImage(fileToImageLikeData(query.ourExpertTeam))

  return (
    <section className={containerStyles.careersFluidImage}>
      <div className="container container-md">
        <div className="row">
          <div className="col-12">
            <GatsbyImage
              image={ourExpertTeam as IGatsbyImageData}
              alt="Our expert team | Codica"
              title="Codica expert team"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerFluidImageSection
