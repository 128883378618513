import { getImage } from 'gatsby-plugin-image'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import * as containerStylesButtons from '~/components/buttons/Button/Button.module.scss'
import ImageFadeSection from '~/components/shared/ImageFadeSection'
import { cx, fileToImageLikeData } from '~/utils'

import useCareersStaticQuery from '../../useCareersStaticQuery'

import * as containerStyles from './CareersHeadSection.module.scss'

const CareersHeadSection = () => {
  const query = useCareersStaticQuery()

  const companyPhoto17 = getImage(fileToImageLikeData(query.companyPhoto17))
  const companyPhoto18 = getImage(fileToImageLikeData(query.companyPhoto18))
  const companyPhoto19 = getImage(fileToImageLikeData(query.companyPhoto19))
  const companyPhoto20 = getImage(fileToImageLikeData(query.companyPhoto20))
  const companyPhoto21 = getImage(fileToImageLikeData(query.companyPhoto21))
  const companyPhoto22 = getImage(fileToImageLikeData(query.companyPhoto22))

  return (
    <section className={containerStyles.careersHeadSection}>
      <div className="container container-md">
        <div className="row">
          <div className="col-12">
            <div className={containerStyles.careersHeadSection__wrapper}>
              <h1 className={containerStyles.careersHeadSection__title}>
                Build your career with Codica
              </h1>
              <p className={containerStyles.careersHeadSection__subtitle}>
                Do you want to grow as a professional, approach new challenges
                and work on various exciting projects? Then Codica is for you!
              </p>
              <AnchorLink
                href="#checkOpenPositions"
                className={cx(
                  containerStylesButtons.button,
                  containerStylesButtons.button__red,
                )}
              >
                Check open positions
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>

      <ImageFadeSection
        imageLeft={companyPhoto17}
        imageRight={companyPhoto20}
        imageCenterLeftTop={companyPhoto18}
        imageCenterLeftBottom={companyPhoto19}
        imageCenterRightTop={companyPhoto22}
        imageCenterRightBottom={companyPhoto21}
        alt="Build your career with our company"
      />
    </section>
  )
}

export default CareersHeadSection
