import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import * as containerStyles from './ImageFadeSection.module.scss'

interface ImageFadeSectionProps {
  imageLeft: IGatsbyImageData | undefined
  imageRight: IGatsbyImageData | undefined
  imageCenterLeftTop: IGatsbyImageData | undefined
  imageCenterLeftBottom: IGatsbyImageData | undefined
  imageCenterRightTop: IGatsbyImageData | undefined
  imageCenterRightBottom: IGatsbyImageData | undefined
  alt: string
}

const ImageFadeSection = ({
  imageLeft,
  imageCenterLeftTop,
  imageCenterLeftBottom,
  imageCenterRightTop,
  imageCenterRightBottom,
  imageRight,
  alt,
}: ImageFadeSectionProps) => (
  <div className={containerStyles.imageFadeSection}>
    <div className={containerStyles.imageFadeSection__left}>
      <div className={containerStyles.imageFadeSection__reveal}>
        <GatsbyImage
          image={imageLeft as IGatsbyImageData}
          alt={`${alt} | Codica`}
          title={alt}
        />
      </div>
    </div>

    <div className={containerStyles.imageFadeSection__center}>
      <div className={containerStyles.imageFadeSection__centerTop}>
        <div className={containerStyles.imageFadeSection__reveal}>
          <div className={containerStyles.imageFadeSection__centerTop_imgOne}>
            <GatsbyImage
              image={imageCenterLeftTop as IGatsbyImageData}
              alt={`${alt} | Codica`}
              title={alt}
            />
          </div>
        </div>
        <div className={containerStyles.imageFadeSection__reveal}>
          <div className={containerStyles.imageFadeSection__centerTop_imgTwo}>
            <GatsbyImage
              image={imageCenterRightTop as IGatsbyImageData}
              alt={`${alt} | Codica`}
              title={alt}
            />
          </div>
        </div>
      </div>
      <div className={containerStyles.imageFadeSection__centerBottom}>
        <div className={containerStyles.imageFadeSection__reveal}>
          <div
            className={containerStyles.imageFadeSection__centerBottom_imgOne}
          >
            <GatsbyImage
              image={imageCenterLeftBottom as IGatsbyImageData}
              alt={`${alt} | Codica`}
              title={alt}
            />
          </div>
        </div>
        <div className={containerStyles.imageFadeSection__reveal}>
          <div
            className={containerStyles.imageFadeSection__centerBottom_imgTwo}
          >
            <GatsbyImage
              image={imageCenterRightBottom as IGatsbyImageData}
              alt={`${alt} | Codica`}
              title={alt}
            />
          </div>
        </div>
      </div>
    </div>

    <div className={containerStyles.imageFadeSection__right}>
      <div className={containerStyles.imageFadeSection__reveal}>
        <GatsbyImage
          image={imageRight as IGatsbyImageData}
          alt={`${alt} | Codica`}
          title={alt}
        />
      </div>
    </div>
  </div>
)

export default ImageFadeSection
