import { PostsSliderMock } from '~/types/mock'

const PostsSlider: PostsSliderMock[] = [
  {
    image: 'postMarketplace',
    title: 'How to Build a Marketplace Website in 16 Steps: The Ultimate Guide',
    link: '/blog/how-to-build-online-marketplace/',
    postDate: 'December 12, 2023',
    readTime: '23',
    titleProgress: 'How to Build a Marketplace Website',
    description:
      'Starting your online marketplace is an exciting experience, but it might feel confusing. Surely, a marketplace website launch can be chaotic, especially when you do not know where to start. To make things easier, we created a list of steps to take.',
  },
  {
    image: 'postMvp',
    title:
      'How to Build a Minimum Viable Product in 2024: The Ultimate Guide for Startup Founders',
    link: '/blog/how-to-create-minimum-viable-product/',
    postDate: 'March 11, 2024',
    readTime: '36',
    titleProgress: 'How to build a MVP',
    description:
      'In the software development world, MVP stands for minimum viable product and, in fact, is one of the most crucial steps when it comes to developing any product. From cost minimization to risk mitigation, MVP has numerous benefits to offer.',
  },
  {
    image: 'postSaas',
    title: 'How to Build a SaaS Product: Step-by-Step Guide',
    link: '/blog/how-to-build-saas-product/',
    postDate: 'December 08, 2023',
    readTime: '19',
    titleProgress: 'How to Build a SaaS Product',
    description:
      'With the growing of SaaS products, more businesses eagerly use such technologies and implement them in their working environment. Understanding SaaS benefits impossible without a proper understanding of the technology itself.',
  },
]

export default PostsSlider
