import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

const ResumeImage = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      hrManagerPhoto: file(
        relativePath: { eq: "vacancy/photo-hr-manager.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const hrManagerPhoto = getImage(fileToImageLikeData(query.hrManagerPhoto))

  return (
    <GatsbyImage
      image={hrManagerPhoto as IGatsbyImageData}
      alt="Talk to a Codica HR Manager | Codica"
      title="Talk to a Codica HR Manager"
      loading="lazy"
    />
  )
}

export default ResumeImage
