// extracted by mini-css-extract-plugin
export var careerWorking__btn = "dL_FN";
export var careerWorking__btnWrapper = "dL_FM";
export var careerWorking__card = "dL_FB";
export var careerWorking__cardBgDark = "dL_FD";
export var careerWorking__cardBgLight = "dL_FC";
export var careerWorking__cardDescription = "dL_FK";
export var careerWorking__cardHorizontal = "dL_FF";
export var careerWorking__cardHorizontalBox = "dL_FG";
export var careerWorking__cardHorizontalImage = "dL_FH";
export var careerWorking__cardImage = "dL_FL";
export var careerWorking__cardTitle = "dL_FJ";