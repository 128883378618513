import { LabeledIcon } from '~/types/data-array'

export interface CareersContentData {
  icon: string
  title: string
  description: string
}

export const info: LabeledIcon[] = [
  {
    icon: 'spriteIcon_46',
    label: 'Diverse projects and domains',
  },
  {
    icon: 'spriteIcon_88',
    label: 'Professional growth',
  },
  {
    icon: 'spriteIcon_19',
    label: 'Latest technologies',
  },
  {
    icon: 'spriteIcon_93',
    label: 'Mentorship and mutual help',
  },
  {
    icon: 'spriteIcon_94',
    label: 'Build products from scratch',
  },
  {
    icon: 'spriteIcon_56',
    label: 'Team of experts',
  },
]

export const contentData: CareersContentData[] = [
  {
    icon: 'spriteIcon_26',
    title: 'Great clients and projects',
    description:
      'We directly work with our clients, who are startups and established businesses from all over the world. Our projects are full-cycle, from the client’s idea to a working solution.',
  },
  {
    icon: 'spriteIcon_60',
    title: 'Friendly management',
    description:
      'Our management is professional, friendly, open to feedback and suggestions, and always looking for ways to improve our working processes and conditions.',
  },
  {
    icon: 'spriteIcon_56',
    title: 'Strong engineering team',
    description:
      'We strongly believe that a solid engineering team is our way to success. When you meet them, you get two for one: a high-skilled mentor and a supportive colleague.',
  },
  {
    icon: 'spriteIcon_92',
    title: 'Flexible working hours',
    description:
      'We appreciate our employees efficiency, and create conditions for delivering best results. This is why we offer flexible working hours for our teammates. Students are welcome!',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Personal development plans',
    description:
      'Each of our team players wants to grow professionally. We make this process easy with ready development plans for each role. You can track the progress and see what skills you need to acquire.',
  },
  {
    icon: 'spriteIcon_69',
    title: 'Regular performance reviews',
    description:
      'We regularly analyze your role inside the team, your skills level and help to realize your potential. As a result, your compensation will be competitive, and revised regularly.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Awesome teammates',
    description:
      'We do not just hire someone who will have the required skills — we are looking for people who will become an important part of the team, who will share our values and spirit.',
  },
  {
    icon: 'spriteIcon_36',
    title: 'Work from anywhere',
    description:
      'We work 100% remotely, so our teammates are free to change their location and working environment. This is possible thanks to our efficient communication and work processes.',
  },
  {
    icon: 'spriteIcon_98',
    title: 'Your opinion matters',
    description:
      'You will have the opportunity to actively participate in each project discussion, suggest new technologies or your own way to implement a new feature.',
  },
]
