import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useCareersStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query CareersStaticQuery {
      quoteCodicaMaksPhoto: file(relativePath: { eq: "avatars/Max.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaSerhiiPhoto: file(relativePath: { eq: "avatars/Sergey.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaInessaPhoto: file(relativePath: { eq: "avatars/Inessa.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      professionalDevelopment: file(
        relativePath: { eq: "careers/proffesional-development.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      newTechnologies: file(
        relativePath: { eq: "careers/new-technologies.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      devopsCulture: file(relativePath: { eq: "careers/devops-culture.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto1: file(
        relativePath: { eq: "company-photo/company-photo-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      companyPhoto2: file(
        relativePath: { eq: "company-photo/company-photo-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto3: file(
        relativePath: { eq: "company-photo/company-photo-3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto4: file(
        relativePath: { eq: "company-photo/company-photo-4.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto5: file(
        relativePath: { eq: "company-photo/company-photo-5.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto6: file(
        relativePath: { eq: "company-photo/company-photo-6.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto7: file(
        relativePath: { eq: "company-photo/company-photo-7.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto8: file(
        relativePath: { eq: "company-photo/company-photo-8.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto9: file(
        relativePath: { eq: "company-photo/company-photo-9.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto10: file(
        relativePath: { eq: "company-photo/company-photo-10.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto11: file(
        relativePath: { eq: "company-photo/company-photo-11.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto12: file(
        relativePath: { eq: "company-photo/company-photo-12.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto13: file(
        relativePath: { eq: "company-photo/company-photo-13.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto14: file(
        relativePath: { eq: "company-photo/company-photo-14.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto15: file(
        relativePath: { eq: "company-photo/company-photo-25.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto17: file(
        relativePath: { eq: "company-photo/company-photo-17.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto18: file(
        relativePath: { eq: "company-photo/company-photo-18.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto19: file(
        relativePath: { eq: "company-photo/company-photo-19.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto20: file(
        relativePath: { eq: "company-photo/company-photo-20.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto21: file(
        relativePath: { eq: "company-photo/company-photo-21.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      companyPhoto22: file(
        relativePath: { eq: "company-photo/company-photo-22.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useCareersStaticQuery
