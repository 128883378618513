import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import * as containerStylesSpriteIcons from '~/styles/modules/SpriteIcons.module.scss'
import { cx } from '~/utils'
import { info } from '~/views/Careers/constants'

import * as containerStyles from './CareersJoinTeamSection.module.scss'

const CareersJoinTeamSection = () => (
  <div className="container container-md">
    <div className="row">
      {info.map(({ label, icon }) => {
        const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
          targetClassName:
            containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
          loadedClassName:
            containerStylesSpriteIcons.spriteIconsWrapper__sprite,
        })

        return (
          <div key={label} className="col-12 col-md-6 col-lg-4">
            <div ref={ref} className={containerStyles.careersJoinOurTeam__card}>
              <div
                className={cx(
                  containerStyles.careersJoinOurTeam__icons,
                  containerStylesSpriteIcons.spriteIconsWrapper,
                  containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
                )}
              />
              <div className={containerStyles.careersJoinOurTeam__cardTitle}>
                {label}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default CareersJoinTeamSection
