import { lazy } from 'react'

import FormInfo from '~/components/forms/FormInfo'
import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'

import ResumeImage from './ResumeImage'

const ResumeForm = lazy(() => import('~/components/forms/ResumeForm'))

type ResumeFormSectionProps = {
  defaultVacancy?: string
}

const ResumeFormSection = ({ defaultVacancy }: ResumeFormSectionProps) => (
  <div className="container container-md">
    <div className="row">
      <div className="col-12 col-md-8">
        <RenderWhenVisible
          withSuspense
          fallback={<EmptyFallback minHeight={503} />}
        >
          <ReCaptchaProvider>
            <ResumeForm defaultVacancy={defaultVacancy} />
          </ReCaptchaProvider>
        </RenderWhenVisible>
      </div>
      <div className="col-12 col-md-4">
        <FormInfo
          isHRInfo
          image={<ResumeImage />}
          alt="Talk to HR Manager | Codica"
          title="Talk to HR Manager"
        />
      </div>
    </div>
  </div>
)

export default ResumeFormSection
