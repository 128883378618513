import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { PhotosQuery, QueryResult, StrapiPage } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Careers from '~/views/Careers/Careers.view'

export type CareersPageProps = QueryResult<StrapiPage & PhotosQuery>

const CareersPage = () => <Careers />

export default CareersPage

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "Careers" }) {
      ...MetaTagInformationPages
    }

    ourExpertTeam: file(relativePath: { eq: "careers/our-expert-team.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    imageDevops: file(relativePath: { eq: "careers/devops-culture.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    imageNewTech: file(relativePath: { eq: "careers/new-technologies.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    imageDevelopment: file(
      relativePath: { eq: "careers/proffesional-development.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    companyPhoto17: file(
      relativePath: { eq: "company-photo/company-photo-17.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    companyPhoto18: file(
      relativePath: { eq: "company-photo/company-photo-18.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    companyPhoto19: file(
      relativePath: { eq: "company-photo/company-photo-19.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    companyPhoto20: file(
      relativePath: { eq: "company-photo/company-photo-20.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    companyPhoto21: file(
      relativePath: { eq: "company-photo/company-photo-21.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }

    companyPhoto22: file(
      relativePath: { eq: "company-photo/company-photo-22.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }
  }
`

export const Head = ({ data }: CareersPageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/careers/"
    />
  )
}
