import { Link } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import Fade from '~/components/shared/Fade'
import { cx, fileToImageLikeData } from '~/utils'

import useCareersStaticQuery from '../../useCareersStaticQuery'

import * as containerStyles from './CareersWorkingSection.module.scss'

const CareersWorkingSection = () => {
  const query = useCareersStaticQuery()

  const newTechnologies = getImage(fileToImageLikeData(query.newTechnologies))
  const devopsCulture = getImage(fileToImageLikeData(query.devopsCulture))
  const professionalDevelopment = getImage(
    fileToImageLikeData(query.professionalDevelopment),
  )

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12 col-md-6">
          <Fade triggerOnce direction="up">
            <div className={containerStyles.careerWorking__card}>
              <div className={containerStyles.careerWorking__cardTitle}>
                Full-cycle software development
              </div>
              <div className={containerStyles.careerWorking__cardDescription}>
                <p>
                  We don’t outstaff, but work directly with our clients, helping
                  them design and develop their products from scratch. We
                  specialize in custom online marketplaces, SaaS and PWA
                  solutions.
                </p>
                <p>
                  All our projects start with the Discovery stage, where we
                  clearly define the requirements of a client, and prepare
                  project requirement documentation and product prototypes.
                </p>
                <p>
                  Our team is composed of all the professionals required for
                  building a web product: frontend and backend developers, UX/UI
                  designers, product managers, QA and DevOps engineers,
                  marketing and SEO specialists. We use the Agile approach in
                  project management.
                </p>
                <p>
                  All this allows us to effectively realize the full-cycle
                  product development for our clients.
                </p>
              </div>
            </div>
          </Fade>
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.careerWorking__card,
                containerStyles.careerWorking__cardBgLight,
              )}
            >
              <div className={containerStyles.careerWorking__cardTitle}>
                DevOps culture
              </div>
              <div className={containerStyles.careerWorking__cardDescription}>
                <p>We always use AWS.</p>
                <p>We automate CI/CD when starting each project:</p>
                <ul>
                  <li>Run linters to check code quality (eslint, rubocop)</li>
                  <li>Run linters for security (trivy, git-secrets)</li>
                  <li>Run unit and integration tests</li>
                  <li>
                    Run automated assembly and deployment on staging /
                    production instances.
                  </li>
                </ul>
                <p>We use Docker to quickly deploy projects locally.</p>
              </div>
              <div className={containerStyles.careerWorking__cardImage}>
                <GatsbyImage
                  image={devopsCulture as IGatsbyImageData}
                  alt="DevOps culture | Codica"
                  title="DevOps culture at Codica"
                />
              </div>
            </div>
          </Fade>
        </div>

        <div className="col-12 col-md-6">
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.careerWorking__card,
                containerStyles.careerWorking__cardBgDark,
              )}
            >
              <div className={containerStyles.careerWorking__cardTitle}>
                Professional development and variety
              </div>
              <div className={containerStyles.careerWorking__cardDescription}>
                <p>We switch between the projects, not get stuck on one.</p>
                <p>
                  We regularly perform retrospectives, this allows continuous
                  improvement for every team member. While everyone has the
                  right to make a mistake, retrospectives help to avoid it in
                  the future.
                </p>
                <p>
                  Thanks to our narrow specialization, we have strong teams of
                  PM, QA, Rails, React, Vue, DevOps specialists.
                </p>
              </div>
              <div className={containerStyles.careerWorking__cardImage}>
                <GatsbyImage
                  image={professionalDevelopment as IGatsbyImageData}
                  alt="Professional development and variety | Codica"
                  title="Professional development and variety"
                />
              </div>
            </div>
          </Fade>
          <Fade triggerOnce direction="up">
            <div className={containerStyles.careerWorking__card}>
              <div className={containerStyles.careerWorking__cardTitle}>
                Standards and clear processes
              </div>
              <div className={containerStyles.careerWorking__cardDescription}>
                <p>
                  We use a set tech stack which allows us to grow expertise:
                </p>
                <ul>
                  <li>Rails for backend</li>
                  <li>React & Vue.js for frontend</li>
                  <li>PostgreSQL as RDBMS</li>
                  <li>ElasticSearch</li>
                  <li>AWS</li>
                </ul>
                <p>
                  We actively develop the culture of code writing and always
                  perform Code Review.
                </p>
                <p>
                  We standardize and document our work processes, which
                  guarantees the best results.
                </p>
                <p>
                  Our clear process of release preparation, testing and
                  publishing allows us to minimize the amount of bugs on
                  production.
                </p>
              </div>
            </div>
          </Fade>
        </div>

        <div className="col-12">
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.careerWorking__card,
                containerStyles.careerWorking__cardHorizontal,
              )}
            >
              <div className={containerStyles.careerWorking__cardHorizontalBox}>
                <div className={containerStyles.careerWorking__cardTitle}>
                  New technologies
                </div>
                <div className={containerStyles.careerWorking__cardDescription}>
                  <p>
                    We continuously work on improving the quality of our work
                    and implement new technologies. When working on new products
                    from scratch, we use the following tech stack:
                  </p>
                  <ul>
                    <li>React or Vue for frontend development (no monolith)</li>
                    <li>TypeScript for React code</li>
                    <li>NextJS for products SEO</li>
                    <li>Redux Toolkit and React-Query for State management</li>
                    <li>The latest versions of Rails / React / Vue</li>
                    <li>Active use of Elastic</li>
                    <li>WebSocket for real-time tasks</li>
                  </ul>
                </div>
              </div>
              <div
                className={containerStyles.careerWorking__cardHorizontalImage}
              >
                <GatsbyImage
                  image={newTechnologies as IGatsbyImageData}
                  alt="New technologies | Codica"
                  title="New technologies at Codica"
                />
              </div>
            </div>
          </Fade>
        </div>

        <div className="col-12">
          <div className={containerStyles.careerWorking__btnWrapper}>
            <Link
              to="/approach/"
              className={containerStyles.careerWorking__btn}
            >
              Our approach
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareersWorkingSection
