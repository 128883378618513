import { getImage } from 'gatsby-plugin-image'

import HeadSection from '~/components/blocks/HeadSection'
import TestimonialPost from '~/components/blocks/TestimonialPost'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import MainLayout from '~/components/layouts/MainLayout'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import ResumeFormSection from '~/components/shared/ResumeFormSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { fileToImageLikeData } from '~/utils'
import CareersHeadSection from '~/views/Careers/components/CareersHeadSection'
import CareersPlaceWorkSection from '~/views/Careers/components/CareersPlaceWorkSection'

import CompanyPostSliderSection from '../Company/components/CompanyPostSliderSection'

import * as containerStyles from './Careers.module.scss'
import CareersFluidImageSection from './components/CareersFluidImageSection'
import CareersJoinTeamSection from './components/CareersJoinTeamSection'
import CareersVacancyListSection from './components/CareersVacancyListSection'
import CareersWorkingSection from './components/CareersWorkingSection'
import { contentData } from './constants'
import useCareersStaticQuery from './useCareersStaticQuery'

const Careers = () => {
  const query = useCareersStaticQuery()

  const quoteCodicaSerhiiPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaSerhiiPhoto),
  )
  const quoteCodicaInessaPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaInessaPhoto),
  )
  const quoteCodicaMaksPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaMaksPhoto),
  )

  return (
    <MainLayout isStaticChat scrollTop>
      <CareersHeadSection />

      <section className={containerStyles.careersJoinTeamSection}>
        <HeadSection
          position="center"
          title="Join our expert team"
          description="If you are looking for a company where you could apply your existing skills and develop new ones, we would love to welcome you aboard. We have strong teams that will help you from day one, and complex projects that will definitely interest you from the technical point of view."
          descriptionLarge
        />
        <CareersJoinTeamSection />
        <TestimonialPost
          image={quoteCodicaSerhiiPhoto}
          name="Serhii"
          position="Tech Lead & Rails expert at Codica"
          quote={[
            {
              text: '“I have been a part of Codica for more than 8 years, and I am very happy to be here. The team is amazing, and I like the management’s positive attitude.',
            },
            {
              text: 'I’m a web developer, so it’s also important to me that everyone can learn and use new technologies in our projects, and that we maintain high quality of code in all the solutions that we implement.”',
            },
          ]}
        />
      </section>

      <CareersFluidImageSection />

      <section className={containerStyles.careersSuccessStoriesSection}>
        <HeadSection
          position="center"
          title="We build web products that users love"
          description="We specialize in building custom online marketplaces and SaaS solutions. Our goal is to continue growing our team’s expertise and become a valued partner and advisor to our clients. Here’s our main principle: Build great web products that bring value to our clients and end users."
          titleLarge
          descriptionLarge
        />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.careersWorkingSection}>
        <HeadSection position="center" title="What working at Codica is like" />
        <CareersWorkingSection />
      </section>

      <section
        className={containerStyles.careersVacancyListSection}
        id="checkOpenPositions"
      >
        <CareersVacancyListSection />
        <TestimonialPost
          image={quoteCodicaInessaPhoto}
          name="Inessa"
          position="UI/UX Lead at Codica"
          quote={[
            {
              text: '“I’ve been with Codica for over 6 years, and what I like most here is the great team. I’m happy to work with such understanding, interesting and simply awesome people.',
            },
            {
              text: 'I also like our streamlined design processes. Thanks to this, I can really dive into each product and understand its potential users. All this makes my work smooth and meaningful.”',
            },
          ]}
        />
      </section>

      <section className={containerStyles.careersWhyJoinSection}>
        <HeadSection position="center" title="Why join Codica?" />
        <ServicesIconSmallCard dataContent={contentData} />
      </section>

      <section className={containerStyles.careersPlaceWorkSection}>
        <HeadSection
          position="center"
          title="An exciting place to work"
          description="Codica Team is not only about work. There’s so much more going on! So we’d better show you what life is like at Codica."
        />
        <CareersPlaceWorkSection />
        <TestimonialPost
          image={quoteCodicaMaksPhoto}
          name="Maksym"
          position="Tech Lead & Rails expert at Codica"
          quote={[
            {
              text: '“I joined Codica almost 6 years ago, and I love working here. The company is constantly growing and changing, and I’m happy to contribute to this development.',
            },
            {
              text: 'There are many things that I like here: regular performance reviews, friendly and engaged team, cozy office, the overall atmosphere. Also, we work on interesting and diverse projects, and it’s a great opportunity to learn something new all the time.”',
            },
          ]}
        />
      </section>

      <section className={containerStyles.careersPostSliderSection}>
        <CompanyPostSliderSection />
      </section>

      <section className={containerStyles.careersResumeSection} id="sendCV">
        <SubtractBlock isTop />
        <HeadSection
          position="center"
          title="Send your CV"
          description="Our goal is to build high-performing and happy teams."
        />
        <ResumeFormSection />
      </section>
    </MainLayout>
  )
}

export default Careers
